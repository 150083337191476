import { useAnimation, useInView } from 'framer-motion'
import Spacer from '../../base/Spacer'
import { SpoqaLight, SpoqaMedium } from '../../base/TextStyle/Spoqa'
import {
  CarouselContainer,
  Container,
  ContentItem,
  ContentWrapper,
  ItemWrapper,
  SelecteIcon,
  SelecteIconContainer,
} from './styles'
import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-material-ui-carousel'

const SectionFour = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const ref = useRef(null)
  const controls = useAnimation()
  const isInView = useInView(ref)
  const itemVariants = {
    start: { opacity: 0, y: 100 },
    end: { opacity: 1, y: 0 },
  }

  const contentContainerVariants = {
    start: {
      opacity: 0,
    },
    end: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.7,
        staggerChildren: 0.1,
      },
    },
  }

  useEffect(() => {
    if (isInView) {
      controls.start('end')
    }
  }, [isInView, controls])

  return (
    <>
      {isTablet ? (
        <CarouselContainer>
          <Carousel
            indicators={false}
            swipe={false}
            navButtonsAlwaysVisible={true}
            animation='slide'
            NavButton={({ onClick, next }) => {
              return (
                <>
                  {next ? (
                    <SelecteIconContainer
                      onClick={() => {
                        onClick()
                      }}
                    >
                      <SelecteIcon src='images/arrow-front-ge.png' />
                    </SelecteIconContainer>
                  ) : (
                    <SelecteIconContainer
                      onClick={() => {
                        onClick()
                      }}
                    >
                      <SelecteIcon src='images/arrow-back-ge.png' />
                    </SelecteIconContainer>
                  )}
                </>
              )
            }}
          >
            <ItemWrapper>
              <ContentItem
                src='images/main/section-four/item1.png'
                alt='item1'
              />
            </ItemWrapper>
            <ItemWrapper>
              <ContentItem
                src='images/main/section-four/item2.png'
                alt='item2'
              />
            </ItemWrapper>
            <ItemWrapper>
              <ContentItem
                src='images/main/section-four/item3.png'
                alt='item3'
              />
            </ItemWrapper>
            <ItemWrapper>
              <ContentItem
                src='images/main/section-four/item4.png'
                alt='item4'
              />
            </ItemWrapper>
          </Carousel>
        </CarouselContainer>
      ) : (
        <Container ref={ref}>
          <ContentWrapper
            variants={contentContainerVariants}
            initial='start'
            animate={controls}
            viewport={{ once: true }}
          >
            <ContentItem
              variants={itemVariants}
              src='images/main/section-four/item1.png'
              alt='item1'
            />
            <Spacer width={17} />
            <ContentItem
              variants={itemVariants}
              src='images/main/section-four/item2.png'
              alt='item2'
            />
            <Spacer width={17} />
            <ContentItem
              variants={itemVariants}
              src='images/main/section-four/item3.png'
              alt='item3'
            />
            <Spacer width={17} />
            <ContentItem
              variants={itemVariants}
              src='images/main/section-four/item4.png'
              alt='item4'
            />
          </ContentWrapper>
        </Container>
      )}
    </>
  )
}

export default SectionFour
