import { Colors } from '@styles/colors'
import Modal from 'react-modal'
import {
  CloseContainer,
  CommonImage,
  ContentContainer,
  ContentLogo,
  DownloadTitleContainer,
  ModalContainer,
} from './styles'
import Spacer from '@components/base/Spacer'
import { SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import { useMediaQuery } from 'react-responsive'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EventModal = ({ isOpen, setIsOpen }: Props) => {
  const ModalStyle = {
    content: {
      border: 0,
      width: 600,
      height: 760,
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      bottom: 'auto',
      borderRadius: '20px',
      backgroundColor: Colors.gf,
      padding: '0',
      boxShadow: '-28px 20px 70px rgba(0, 0, 0, 0.1)',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 100,
    },
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={ModalStyle}
      >
        <ModalContainer>
          <CommonImage
            width={270}
            src='images/modal/event_present.png'
            alt='event_present'
          />
          <Spacer height={27} />
          <CommonImage
            width={314}
            src='images/modal/event_title.png'
            alt='title'
          />
          <Spacer height={30} />
          <CommonImage
            width={35}
            src='images/modal/event_arrow.png'
            alt='arrow'
          />
          <Spacer height={10} />
          <CommonImage
            width={212}
            src='images/modal/event_qr.png'
            alt='event_qr'
          />
          <Spacer height={12} />
          <SpoqaMedium size={24} color={Colors.g9}>
            QR코드 다운로드
          </SpoqaMedium>
        </ModalContainer>
        <CloseContainer
          onClick={() => {
            setIsOpen(false)
          }}
        >
          <SpoqaMedium size={25} color={Colors.g9}>
            닫기
          </SpoqaMedium>
        </CloseContainer>
      </Modal>
    </>
  )
}

export default EventModal
