import React from 'react'
import styled from 'styled-components'

type QNAProps = {
  title: string
  content: string
  link?: any
}

const QNA = ({ title, content, link }: QNAProps) => {
  return (
    <Container onClick={link}>
      <Content>
        <Quition>{title}</Quition>
        <Answer>{content}</Answer>
      </Content>
      <ArrowButton src='/icons/arrow_right.svg' />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
`

const Quition = styled.p`
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #19191b;
`
const Answer = styled.p`
  color: #999ba9;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ArrowButton = styled.img``

export default QNA
