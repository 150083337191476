import styled from 'styled-components'
import { Colors } from '../styles/colors'
import { isAndroid } from 'react-device-detect'
import { useEffect, useState } from 'react'
import DownloadModal from '@components/base/modal/DownloadModal'
import { useMediaQuery } from 'react-responsive'
import EventModal from '@components/base/modal/EventModal'
import NoticeModal from '@components/base/modal/NoticeModal'

const EventKbPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenNoticeModal, setIsOpenNoticeModal] = useState(true)
  const isMobile = useMediaQuery({ query: '(max-width: 720px)' })

  const handleClickDownload = () => {
    if (isMobile) {
      const marketURL = isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.qniverse.ipo'
        : 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484'
      window.location.href = marketURL
    } else {
      setIsOpenModal(true)
    }
  }

  return (
    <>
      <Container>
        <ContentWrapper>
          <ContetnImage src='images/event/kb_event_modified.png' />
          <ContetnImage src='images/event/kb_event2.png' />
          <ContetnImage src='images/event/kb_event3.png' />
        </ContentWrapper>
        <Btn onClick={handleClickDownload}>앱 다운로드</Btn>
        {isOpenModal && (
          <EventModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
        )}
        {isOpenNoticeModal && (
          <NoticeModal
            isOpen={isOpenNoticeModal}
            setIsOpen={setIsOpenNoticeModal}
          />
        )}
      </Container>
    </>
  )
}

export default EventKbPage

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  width: 720px;
  @media (max-width: 720px) {
    width: 100%;
  }
`
const ContetnImage = styled.img`
  width: 100%;
`

const Btn = styled.div`
  font-family: 'SpoqaHanSansNeoBold', sans-serif;
  color: ${Colors.g12};
  font-size: 24px;
  box-shadow: 0px 3px 10px 0px rgba(40, 31, 0, 0.3);
  position: fixed;
  bottom: 30px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  background-color: ${Colors.primary_ios};
  box-shadow: 0px 4px 16px rgba(109, 109, 109, 0.07);
  border-radius: 100px;
  padding: 28px 80px;
  @media (max-width: 720px) {
    padding: 14px 40px;
    border-radius: 28.823px;
    font-size: 16px;
  }
`
