import React, { useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { SpoqaMedium } from '@components/base/TextStyle/Spoqa'
import DownloadModal from '@components/base/modal/DownloadModal'

const DownloadButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen ? <DownloadModal isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
      <ButtonContainer
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <SpoqaMedium>앱 다운로드</SpoqaMedium>
      </ButtonContainer>
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${Colors.primary_ios};
  width: 110px;
  height: 40px;
  margin-left: 42px;
  cursor: pointer;
`

export default DownloadButton
