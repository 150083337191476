import React from 'react'
import styled from 'styled-components'
import { Colors } from '@styles/colors'
import { useHistory } from 'react-router-dom'

const ContactBox = () => {
  const history = useHistory()
  return (
    <Container>
      <Content>
        <Title>상담원 채팅</Title>
        <SubTitle>
          {`일육공 서비스에 궁금한 사항이 있으신가요? \n 상담원에게 문의를 남겨주세요.`}
          <div
            style={{ color: '#999BA9', marginTop: '4px' }}
          >{`상담시간 am 9:00 ~ pm 6:00`}</div>
        </SubTitle>
      </Content>
      <Button
        onClick={() => {
          history.push('/', { prev: 'inquire' })
        }}
      >
        문의하기
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #eaeef3;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  width: 100%;
  height: 188px;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.p`
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`
const SubTitle = styled.p`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  white-space: pre-line;
  color: #555564;
`

const Button = styled.button`
  width: 100%;
  height: 41px;
  padding: 12px;
  box-sizing: border-box;
  background-color: ${Colors.bg_focus};
  border-radius: 8px;
  color: #fff;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  cursor: pointer;
`

export default ContactBox
