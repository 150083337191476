import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  width: 100%;
  height: 266px;
  background-color: ${Colors.maingrey_bg};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 24px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 1200px) {
    height: 300px;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    height: 266px;
  }
  @media (max-width: 480px) {
    height: 178px;
    padding: 22px;
  }
`
export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1200px) {
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 22px;
    justify-content: center;
  }
`
export const LogoContainer = styled.div``
export const Logo = styled.img`
  @media (max-width: 767px) {
    height: 40px;
    width: 40px;
  }
`
export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CompanySiteContainer = styled.div`
  width: 228px;
  height: 60px;
  border: solid 1px ${Colors.g9};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 224px;

  @media (max-width: 1200px) {
    width: 228px;
    height: 60px;
    margin-left: 0;
    margin-top: 24px;
  }
  @media (max-width: 767px) {
    width: 158px;
    height: 45px;
    border-radius: 4px;
  }
  @media (max-width: 480px) {
    width: 96px;
    height: 24px;
  }
`

export const ArrowFront = styled.img`
  @media (max-width: 767px) {
    width: 4px;
    height: 8px;
  }
`

export const MobileContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TermsText = styled.div`
  display: flex;
  cursor: pointer;
  @media (max-width: 767px) {
  }
`
