import Spacer from '@components/base/Spacer'
import styled from 'styled-components'

declare global {
  interface Window {
    webkit: any
    WebViewBridge: any
  }
}

const SamsungTest = () => {
  let paramSession =
    '{"funcName":"SessionExpired","parameters":{"step":"online1111"}}'

  let paramClose = '{"funcName":"closeWebView"}'

  const handleSession = () => {
    alert(`window.webkit.messageHandlers.toNative.postMessage(${paramSession})`)

    window.webkit.messageHandlers.toNative.postMessage(paramSession)
  }

  const handleClose = () => {
    alert(` window.webkit.messageHandlers.toNative.postMessage(${paramClose})`)
    window.webkit.messageHandlers.toNative.postMessage(paramClose)
  }

  const handleSessionM = () => {
    alert(
      `window.webkit.messageHandlers.toNative.postMessage(JSON.stringify(${paramSession}))`
    )
    window.webkit.messageHandlers.toNative.postMessage(
      JSON.stringify(paramSession)
    )
  }

  const handleCloseM = () => {
    alert(
      `window.webkit.messageHandlers.toNative.postMessage(JSON.stringify(${paramClose}))=========${navigator.userAgent}`
    )
    window.webkit.messageHandlers.toNative.postMessage(
      JSON.stringify(paramClose)
    )
  }

  let userAgent = navigator.userAgent.toLowerCase()
  if (
    userAgent.match('iphone') ||
    userAgent.match('ipad') ||
    userAgent.match('ipod')
  ) {
    // ios app interface
  } else {
    // android app interface
  }

  return (
    <Container>
      <Spacer height={100} />
      <Button onClick={handleSession}>Session Expire - parmeter</Button>
      <Spacer height={40} />
      <Button onClick={handleClose}>Close Webview - parmeter</Button>
      <Spacer height={40} />
      <Button onClick={handleSessionM}>Session Expire - json.stringify </Button>
      <Spacer height={40} />
      <Button onClick={handleCloseM}>Close Webview - json.stringify</Button>
      <Spacer height={40} />

      <div>
        삼성조건 :
        {userAgent.match('iphone') ||
        userAgent.match('ipad') ||
        userAgent.match('ipod') ? (
          <Row className=''>{`ios: ${navigator.userAgent}`}</Row>
        ) : (
          <Row>{`aos: ${navigator.userAgent}`}</Row>
        )}
      </div>
    </Container>
  )
}

export default SamsungTest

const Row = styled.div`
  width: 100%;
  border-bottom: 1px solid blue;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: cneter;
  width: 100%;
  height: 100vh;
  border: 1px solid red;
`

const Button = styled.button`
  width: 300px;
  height: 50px;
  border-radius: 30px;
  border: 3px solid gray;
  &:active {
    background-color: lightgray;
    border-color: darkgray;
  }
  }
`
