import { Colors } from '@styles/colors'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.section`
  width: 100%;
  height: 72px;
  padding: 0 18%;
  position: fixed;
  z-index: 100;
  background-color: ${Colors.gf};
  border-bottom: 1px solid ${Colors.gE2};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1023px) {
    padding: 0 10%;
  }

  @media (max-width: 700px) {
    padding: 0 5%;
    height: 48px;
  }
`
export const LogoWrapper = styled.div`
  padding: 25px 0;
  @media (max-width: 700px) {
    padding: 12px 0;
  }
`
export const LogoImage = styled(motion.img)`
  width: 47.925px;
  height: 22.558px;
  flex-shrink: 0;
  cursor: pointer;
`

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 700px) {
    display: none;
  }
`

export const BtnCalender = styled.div<{ currentPath: string }>`
  padding: 25px 0;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.currentPath === '/calendar'
      ? `3px solid${Colors.primary_ios}`
      : 'none'};
  &:hover {
    border-bottom: 3px solid ${Colors.primary_ios};
  }
`

export const BtnScore = styled.div<{ currentPath: string }>`
  padding: 25px 0;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.currentPath === '/info' ? `3px solid${Colors.primary_ios}` : 'none'};
  &:hover {
    border-bottom: 3px solid ${Colors.primary_ios};
  }
`
export const BtnFAQ = styled.div<{ currentPath: string }>`
  padding: 25px 0;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.currentPath === '/faq' ? `3px solid${Colors.primary_ios}` : 'none'};
  &:hover {
    border-bottom: 3px solid ${Colors.primary_ios};
  }
`

//mobile
export const MobileMenuIconBtn = styled.div`
  display: none;
  padding-top: 8px;
  @media (max-width: 700px) {
    display: block;
  }
`
export const MobileMenuIcon = styled.img`
  display: none;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  @media (max-width: 700px) {
    display: block;
  }
`

export const MobileListMenu = styled.div`
  width: 100%;
  height: 187px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.gf};
`

export const MobileListTextWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid ${Colors.gE2};
  box-sizing: border-box;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`
