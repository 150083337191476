import CalendarPage from '../../pages/CalendarPage'
import FaqPage from '../../pages/FaqPage'
import InfoPage from '../../pages/InfoPage'
import MainPage from '../../pages/MainPage'
import { createRouterItem, RouterItemType } from '../../router/routerItem'
import EventKbPage from '../../pages/EventKbPage'
import AppLinkBridge from '../../pages/AppLinkBridge'
import WelcomeBridge from '../../pages/WelcomeBridge'
import WelcomeCallSchemePage from '../../pages/WelcomeCallSchemePage'
import WelcomeBridgePage from '../../pages/WelcomeBridge'
import RedirectSamsungPage from '../../pages/RedirectSamsungPage'
import SamsungTest from '../../pages/SamsungTest'
import InquirePage from '../../pages/InquirePage'
import MainTestPage from '../../pages/MainTestPage '
import KbankAppTest from '../../pages/KbankAppTest'

export const MainRouterItem = createRouterItem({
  name: 'main',
  path: '/',
  component: MainPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const InfoRouterItem = createRouterItem({
  name: 'info',
  path: '/info',
  component: InfoPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const FaqRouterItem = createRouterItem({
  name: 'faq',
  path: '/faq',
  component: FaqPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const CalendarRouterItem = createRouterItem({
  name: 'calendar',
  path: '/calendar',
  component: CalendarPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const EventKbRouteItem = createRouterItem({
  name: 'kb-event',
  path: '/kb-event',
  component: EventKbPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})
export const AppLinkRouteItem = createRouterItem({
  name: 'app-link-bridge',
  path: '/app-link-bridge/:ci?',
  component: AppLinkBridge,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const WelcomeBridgeRouteItem = createRouterItem({
  name: 'welcome-bridge',
  path: '/welcome-bridge',
  component: WelcomeBridgePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const WelcomeCallSchemeRouteItem = createRouterItem({
  name: 'welcome-call-scheme',
  path: '/welcome-call-scheme',
  component: WelcomeCallSchemePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const RedirectSamsungRouteItem = createRouterItem({
  name: 'redirect-samsung',
  path: '/redirect-samsung',
  component: RedirectSamsungPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const SamsungTestRouteItem = createRouterItem({
  name: 'test-samsung',
  path: '/test-samsung',
  component: SamsungTest,
  type: RouterItemType.DEFAULT,
  exact: true,
})

// 문의하기 페이지
export const InquireItem = createRouterItem({
  name: 'inquire',
  path: '/inquire',
  component: InquirePage,
  type: RouterItemType.DEFAULT,
  exact: true,
})

export const MainTestRouterItem = createRouterItem({
  name: 'main-test',
  path: '/main-test',
  component: MainTestPage,
  type: RouterItemType.DEFAULT,
  exact: true,
})


export const KbankAppTestRouterItem = createRouterItem({
  name: 'app-test',
  path: '/app-test',
  component: KbankAppTest,
  type: RouterItemType.DEFAULT,
  exact: true,
})

const baseRouterItems = [
  MainRouterItem,
  InfoRouterItem,
  FaqRouterItem,
  CalendarRouterItem,
  EventKbRouteItem,
  AppLinkRouteItem,
  WelcomeBridgeRouteItem,
  WelcomeCallSchemeRouteItem,
  RedirectSamsungRouteItem,
  SamsungTestRouteItem,
  InquireItem,
  MainTestRouterItem,
  //
  KbankAppTestRouterItem
]

export default baseRouterItems
