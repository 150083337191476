import { useMediaQuery } from 'react-responsive'
import {
  ArrowFront,
  CompanySiteContainer,
  Container,
  ContentsContainer,
  LeftWrapper,
  Logo,
  LogoContainer,
  TermsText,
} from './styles'
import Spacer from '@components/base/Spacer'
import { SpoqaMedium, SpoqaRegular } from '@components/base/TextStyle/Spoqa'
import { Colors } from '@styles/colors'

const Footer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })

  const openSite = () => {
    window.open('http://www.ml-invest.co.kr/')
  }
  const openTerms = () => {
    window.open(
      'https://thoughtful-pressure-c0b.notion.site/4374ac54a2dc4234abe9e667604c3648?pvs=4'
    )
  }

  const openPersonalInfo = () => {
    window.open(
      'https://thoughtful-pressure-c0b.notion.site/7ba00333a8fb4040aec6768d53e608ce?pvs=4'
    )
  }

  return (
    <Container>
      <LeftWrapper>
        <LogoContainer>
          <Logo src='images/fotter-logo.png' />
        </LogoContainer>
        <Spacer width={isMobile ? 18 : isTablet ? 24 : 40} />
        <ContentsContainer>
          <TermsText>
            <SpoqaRegular
              size={isMobile ? 10 : isTablet ? 14 : 20}
              onClick={openTerms}
            >
              서비스 이용약관
            </SpoqaRegular>
            <Spacer width={6} />
            <SpoqaRegular size={isMobile ? 10 : isTablet ? 14 : 20}>
              {' '}
              |{' '}
            </SpoqaRegular>
            <Spacer width={6} />
            <SpoqaRegular
              size={isMobile ? 10 : isTablet ? 14 : 20}
              onClick={openPersonalInfo}
            >
              개인정보 처리방침
            </SpoqaRegular>
          </TermsText>
          {isTablet ? (
            <>
              <SpoqaRegular
                size={isMobile ? 10 : isTablet ? 14 : 20}
                lineHeight={isMobile ? 14 : isTablet ? 18 : 28}
              >
                엠엘투자자문(주) 06246
              </SpoqaRegular>
              <SpoqaRegular
                size={isMobile ? 10 : isTablet ? 14 : 20}
                lineHeight={isMobile ? 14 : isTablet ? 18 : 28}
              >
                서울특별시 강남구 역삼로 17길 10 부강빌딩 3층
              </SpoqaRegular>
            </>
          ) : (
            <>
              <Spacer height={16} />
              <SpoqaRegular
                size={isMobile ? 10 : isTablet ? 14 : 20}
                lineHeight={isMobile ? 14 : isTablet ? 18 : 28}
              >
                엠엘투자자문(주) 06246 서울특별시 강남구 역삼로 17길 10 부강빌딩
                3층
              </SpoqaRegular>
              <Spacer height={2} />
            </>
          )}
          <SpoqaRegular
            size={isMobile ? 10 : isTablet ? 14 : 20}
            lineHeight={isMobile ? 14 : isTablet ? 18 : 28}
          >
            서비스 문의처 : 엠엘투자자문(02-6949-0045)
          </SpoqaRegular>

          {isTablet ? <Spacer height={4} /> : <Spacer height={12} />}
          <SpoqaRegular
            size={isMobile ? 10 : isTablet ? 14 : 20}
            color={Colors.g9}
            lineHeight={isMobile ? 14 : isTablet ? 18 : 28}
          >
            © ML Investment Advisory Co.,Ltd. All Rights Reserved.
          </SpoqaRegular>
        </ContentsContainer>
      </LeftWrapper>

      <CompanySiteContainer onClick={openSite}>
        <SpoqaMedium size={isMobile ? 10 : isTablet ? 18 : 24}>
          company site
        </SpoqaMedium>
        <Spacer width={isMobile ? 5 : 16} />
        <ArrowFront src='/images/arrow-front.png' />
      </CompanySiteContainer>
    </Container>
  )
}

export default Footer
