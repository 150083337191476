import Spacer from '../../base/Spacer'
import { SpoqaBold, SpoqaRegular } from '../../base/TextStyle/Spoqa'
import {
  Container,
  ContentWrapper,
  GrayBackground,
  LeftContent,
  RightContent,
  BoldText,
  RegularText,
  LeftImage,
  RightImage,
  LeftImageWrapper,
  ImageWrapper,
  PaddingWrapper,
  ImageMobile,
} from './styles'
import { Colors } from '@styles/colors'
import { useMediaQuery } from 'react-responsive'

const SectionSeven = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' })
  const isTabletL = useMediaQuery({ query: '(max-width: 1430px)' })
  return (
    <Container>
      {isTabletL ? (
        <ContentWrapper>
          <PaddingWrapper>
            <BoldText
              size={isMobile ? 14 : 24}
              color={Colors.primary_ios}
              weight={700}
            >
              홈 • 주요일정
            </BoldText>
            <Spacer height={16} />
            <SpoqaBold
              size={isMobile ? 22 : 48}
              color={Colors.g12}
              lineHeight={isMobile ? 28 : 60}
            >
              흩어진 청약 정보,
              <br />
              한곳에서 <br />
              모바일로 더 손쉽게
            </SpoqaBold>
            <Spacer height={32} />
            <SpoqaRegular
              size={isMobile ? 13 : 19}
              color={Colors.g12}
              lineHeight={isMobile ? 18.2 : 30}
            >
              공모주 정보를 확인하기 위해 여기저기
              <br />
              사이트를 돌아다녀 보신 적이 있으신가요?
              <br />
              이젠 그러실 필요없이 일육공 한 곳에서
              <br />
              확인하세요!
            </SpoqaRegular>
          </PaddingWrapper>
          <ImageWrapper>
            <ImageMobile
              src={
                !isTablet
                  ? 'images/main/section-seven/right_phone.png'
                  : 'images/main/section-seven/mobile.png'
              }
              alt='phone2_mobile'
            />
          </ImageWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <LeftContent>
            <BoldText
              size={24}
              color={Colors.primary_ios}
              weight={700}
              indent={105}
            >
              홈 • 주요일정
            </BoldText>
            <Spacer height={16} />
            <BoldText
              size={48}
              color={Colors.g12}
              lineHeight={62.4}
              indent={105}
            >
              흩어진 청약 정보, 한곳에서
            </BoldText>
            <BoldText
              size={48}
              color={Colors.g12}
              lineHeight={62.4}
              indent={105}
            >
              모바일로 더 손쉽게
            </BoldText>
            <Spacer height={40} />
            <RegularText
              size={22}
              lineHeight={30.8}
              color={Colors.g12}
              indent={105}
            >
              공모주 정보를 확인하기 위해 여기저기 사이트를 돌아다녀 보신 적이{' '}
            </RegularText>
            <RegularText
              size={22}
              lineHeight={30.8}
              color={Colors.g12}
              indent={105}
            >
              있으신가요? 이젠 그러실 필요없이 일육공 한 곳에서 확인하세요!
            </RegularText>
            <LeftImageWrapper>
              <LeftImage
                src={'/images/main/section-seven/left_phone.png'}
                alt='left_phone'
              />
            </LeftImageWrapper>
          </LeftContent>
          <RightContent>
            <RightImage
              src={'/images/main/section-seven/right_phone.png'}
              alt='right_phone'
            />
          </RightContent>
        </ContentWrapper>
      )}

      <GrayBackground />
    </Container>
  )
}

export default SectionSeven
