import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.gf};
  position: relative;
  z-index: 1;
  padding-top: 222px;

  @media (max-width: 767px) {
    padding-top: 150px;
  }
`

export const GrayBackground = styled.div`
  width: 100%;
  height: 221px;
  background-color: ${Colors.maingrey_bg};
  position: absolute;
  bottom: 0;
  @media (max-width: 767px) {
    background-color: transparent;
  }
`

export const ContentWrapper = styled.div`
  width: 75%;
  z-index: 1;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1850px) {
    width: 92%;
  }
  @media (max-width: 1430px) {
    position: relative;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`
export const PaddingWrapper = styled.div`
  padding: 0 20px;
`
export const LeftContent = styled.div`
  width: 60%;
`
export const RightContent = styled.div`
  width: 40%;
`
export const LeftImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
export const LeftImage = styled.img`
  width: 85%;
`

export const RightImage = styled.img`
  width: 100%;
`

export const RegularText = styled.div<{
  size: number
  color: Colors
  indent?: number
  lineHeight?: number
  weight?: number
}>`
  font-family: 'SpoqaHanSansNeoRegular', sans-serif;
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  text-indent: ${(props) => (props.indent ? props.indent : 0)}px;
  line-height: ${(props) => props.lineHeight}px;
  font-weight: ${(props) => props.weight};
`
export const BoldText = styled.div<{
  size: number
  color: Colors
  indent?: number
  lineHeight?: number
  weight?: number
}>`
  font-family: 'SpoqaHanSansNeoBold', sans-serif;
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  text-indent: ${(props) => (props.indent ? props.indent : 0)}px;
  line-height: ${(props) => props.lineHeight}px;
  font-weight: ${(props) => props.weight};
`

//mobile
export const ImageWrapper = styled.div`
  width: 100%;
  margin-top: 70px;
  position: relative;
  @media (max-width: 1430px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
  }
`

export const ImageMobile = styled.img`
  object-fit: contain;
  width: 100%;
  @media (max-width: 1430px) {
    width: 40%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`
