import Spacer from "@components/base/Spacer"

const KbankAppTest = () => {

    const OpenKBANKAppButton = () => {

        if (/iPhone/i.test(window.navigator.userAgent)) {
            window.open(
              'itms-apps://apps.apple.com/app/id1178872627'
            )
          } else {
            window.open(
             "intent://#Intent;scheme=kbank;package=com.kbankwith.smartbank;end;"
            )
          }
   
    };
    
    return (
        <div>
            <Spacer height={200}/>
        <button  onClick={OpenKBANKAppButton}>버튼느니아ㅓㄹ니ㅏㅓㅁㄹ ??</button>


        </div>
    )
}


export default KbankAppTest