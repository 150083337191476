import Modal from 'react-modal'
import styled from 'styled-components'
import { ButtonWrapper, CloaseBtn, ImageWrapper } from './styles'
import { SpoqaMedium } from '@components/base/TextStyle/Spoqa'

interface Props {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NoticeModal = ({ isOpen, setIsOpen }: Props) => {
  const ModalStyle = {
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'transparent',
      padding: 0,
      overflow: 'visible',
      border: 'none',
      width: '80%',
      maxWidth: '600px',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 60,
      padding: 0,
    },
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={ModalStyle}
    >
      <div>
        <ImageWrapper src='images/event/popup_event.png' alt='popup-event' />
        <ButtonWrapper>
          <CloaseBtn onClick={() => setIsOpen(false)}>
            <SpoqaMedium>닫기</SpoqaMedium>
          </CloaseBtn>
        </ButtonWrapper>
      </div>
    </Modal>
  )
}

export default NoticeModal
