import { ReactElement, useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/Header'
import routerItems from './router/paths'
import RouterRenderer from './router/routerRender'
import Footer from '@components/Footer'
import { Colors } from '@styles/colors'
import ChannelTalkManager from '@components/ChannelTalkManager'
import FloatBtn from '@components/base/FloatBtn'

const Wrapper = styled.div`
  background-color: ${Colors.gf};
  min-height: 100vh;
`

function App(): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  const isEventPage = window.location.href.includes('kb-event') ? true : false
  const applinkPage =
    window.location.href.includes('app-link-bridge') ||
    window.location.href.includes('welcome')
      ? true
      : false

  const inquirePage = window.location.href.includes('inquire') ? true : false

  return (
    <Wrapper>
      {!isEventPage && !applinkPage && !inquirePage && <Header />}
      {!isEventPage && !applinkPage && !inquirePage && (
        <FloatBtn isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      <ChannelTalkManager />
      <Switch children={RouterRenderer(routerItems)} />
      {!isEventPage && !applinkPage && !inquirePage && <Footer />}
    </Wrapper>
  )
}

export default App
