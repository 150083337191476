import styled from 'styled-components'

export const ImageWrapper = styled.img`
  width: 100%;
  height: auto;
  z-index: 70;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  background-color: #eaeef3;
  display: flex;
  justify-content: center;
  border-radius: 0 0 30px 30px;
`

export const CloaseBtn = styled.div`
  color: #555564;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  cursor: pointer;
  line-height: 130%;
  @media (max-width: 600px) {
    font-size: 16px;
    padding: 10px 20px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
    padding: 8px 18px;
  }
  @media (max-width: 200px) {
    height: 0;
  }
`
