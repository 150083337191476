import { Colors } from '@styles/colors'
import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 686px;
  padding: 71px 0 0 0;
`

export const DownloadTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 75px;
  padding-right: 87px;
`

export const DonwloadTitle = styled.div`
  font-size: 18px;
  color: ${Colors.g9};
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 87px 0 56px;
  align-items: center;
  @media (max-width: 1445px) {
    padding: 0 30px;
  }
`

export const ContentLogo = styled.img`
  width: 33.47px;
  height: 35.86px;
`

export const CommonImage = styled.img<{ width: number; height?: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => (props.height ? props.height : 'auto')}px;
  object-fit: contain;
`

export const CloseContainer = styled.div`
  border-top: 1.5px solid ${Colors.ge};
  width: 100%;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
`
